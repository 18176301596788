<script>
import {transformDataToFormContent} from "~/services/parameters";
import formHandler from "~/mixins/formHandler";
import Rental from "~/modules/ask/services/Rental";
 console.log('coucou')
export default {
  name: "AskCartItem",
  mixins: [formHandler],
  emits: ["show-equipments", 'toogleItem', 'changeItem'],
  data () {
    return {
      isSelected: true
    }
  },
  props: {
    availableItemQuantity: {
      type: Object,
      default: () => {
        return {}
      }
    },
    ask: {
      type: Object,
      default: () => {
        return {}
      }
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateItem (item) {
      let dataFormContent = {
        'id': item.id,
        'quantity': item.quantity,
        'valide': item.valide
      }
      this.$emit('changeItem', item)
    },
    showEquipement (annonce) {
      this.$emit('show-equipments', annonce)
    },
    toogleSelectItem (value) {
      this.$emit('toogleItem', value, this.item)
    },
    formatCoverPath (path) {
      if (path) {
        if (path.includes('/display-media') === false) {
          return '/display-media' + path + '?w=300&r=1'
        } else {
          return path + '?w=300&r=1'
        }
      } else {
        return '/img/sans-photo.jpg'
      }
    }
  },
  computed: {
    isInShop () {
      return this.$route.path.includes('shop')
    },
    ownerAdLink () {
    },
    ownerBundleLink () {

    },
    Model () {
      return Rental
    },
    formSave () {
      return true
    },
    formKey () {
      return 'cartItem'
    },
    apiUrl () {
      return '/api/user/ask'
    },
    formatPrice () {
      return this.$currencyFormat.format(this.ask.prix_location_ht)
    }
  }
}
</script>

<template>
  <div class="ask-cart-item-row" :class="item.valide ? '' : 'unvalid-item'">
    <div v-if="selectable" class="ask-cart-checkbox">
      <el-checkbox v-model="item.valide" @change="toogleSelectItem($event)"></el-checkbox>
    </div>
    <div class="cart-item-image">
      <router-link v-if="isInShop" :to="item.annonces && item.annonces.bundle ? {name: 'bundle-edition', params: { id: item.annonces.id}} : {name: 'product-edition', params: { id: item.annonces.id}} ">
        <img :src="formatCoverPath(item.annonces.cover.path)" alt="item.annonces.photos[0].alt">
      </router-link>
      <img v-else :src="formatCoverPath(item.annonces.cover.path)" alt="item.annonces.photos[0].alt">
    </div>
    <div class="cart-item-content pe-0">
      <div class="d-flex align-items-center justify-content-between w-100 pe-0 me-0">
        <div>
          <router-link v-if="isInShop" :to="item.annonces && item.annonces.bundle ? {name: 'bundle-edition', params: { id: item.annonces.id}} : {name: 'product-edition', params: { id: item.annonces.id}}">
            <h4 class="cart-item-title mb-0">
              {{ item.annonces.titre }}
              <span v-if="!item.valide" class="badge badge-danger ms-2">Indispo.</span>
            </h4>
          </router-link>
          <a v-else :href="path('lighty_share_user_detail_annonce',{ 'id': item.annonces.id})" class="info">
            <h4 class="cart-item-title mb-0">
              {{ item.annonces.titre }}
              <span v-if="!item.valide" class="badge badge-danger ms-2">Indispo.</span>
            </h4>
          </a>
        </div>
        <div class="price-container">
          <span>{{ formatPrice }}</span> HT
        </div>
      </div>
      <div v-if="item.annonces && !item.annonces.bundle">
        <a v-if="item.annonces && !item.annonces.bundle && item.annonces.detailsannonce && item.annonces.detailsannonce.length" href="#" @click.prevent="showEquipement(item.annonces.detailsannonce)">Afficher les {{item.annonces.detailsannonce.length}} équipements</a>
        <div class="cart-item-quantity">
          <span>{{ item.quantite }}</span>
        </div>
        <div v-if="selectable" class="inline-form-item">
          <label for="">Quantité :</label>
          <el-select v-model="item.quantity" class="m-2" placeholder="Select" style="width: 80px" :disabled="availableItemQuantity && availableItemQuantity.stockAvailable ? availableItemQuantity.stockAvailable <= 1 : false " @change="updateItem(item)">
            <el-option
                style="width: 80px"
                v-for="n in availableItemQuantity && availableItemQuantity.stockAvailable ? availableItemQuantity.stockAvailable : 0"
                :key="n"
                :label="n"
                :value="n"
            />
          </el-select>
        </div>
        <div v-else class="inline-form-item">
          <label for="">Quantité :</label>
          <span>{{item.quantity}}</span>
        </div>
      </div>
      <div v-else>
        <div v-if="selectable" class="inline-form-item">
          <label for="">Quantité :</label>
          <el-select v-model="item.quantity" class="m-2" placeholder="Select" style="width: 80px" :disabled="availableItemQuantity && availableItemQuantity.stockAvailable ? availableItemQuantity.stockAvailable <= 1 : false " @change="updateItem(item)">
            <el-option
                style="width: 80px"
                v-for="n in availableItemQuantity && availableItemQuantity.stockAvailable ? availableItemQuantity.stockAvailable : 0"
                :key="n"
                :label="n"
                :value="n"
            />
          </el-select>
        </div>
        <div v-else class="inline-form-item">
          <label for="">Quantité :</label>
          <span>{{item.quantity}}</span>
        </div>
        <div v-for="bundleItem in item.annonces.bundle.bundleItems" :href="path('lighty_share_user_detail_annonce',{ 'id': bundleItem.annonceId})" target="_blank" class="bundle-item-card" >
          <div class="img">
            <router-link v-if="isInShop" :to="{name: 'product-edition', params: { id: bundleItem.annonceId}}">
              <img :src="formatCoverPath(bundleItem.cover)" alt="">
            </router-link>
            <img v-else :src="formatCoverPath(bundleItem.cover)" alt="">
          </div>
          <router-link v-if="isInShop" :to="{name: 'product-edition', params: { id: bundleItem.annonceId}}" class="info">
            <div class="quantity">
              x {{ item.quantity * bundleItem.quantity }}
            </div>
            <div class="title">
              {{bundleItem.title}}
            </div>
          </router-link>
          <a v-else :href="path('lighty_share_user_detail_annonce',{ 'id': bundleItem.annonceId })" class="info">
            <div class="quantity">
              x {{ item.quantity * bundleItem.quantity }}
            </div>
            <div class="title">
              {{bundleItem.title}}
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.cart-item-content {
  width: 100%;
  padding-right: 0;
}

.cart-item-title {
  font-size: 16px !important;
}

.bundle-item-card {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  background: #f8f8f8;
  border-radius: 4px;
}

.ask-cart-item-row {
  display: flex;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  background: #fff;
  margin-bottom: 10px;
}

.cart-item-image {
  width: 80px;
  margin-right: 15px;
  flex-shrink: 0;
}

.cart-item-image img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.price-container {
  padding-right: 15px;
  white-space: nowrap;
}
</style>

