<script>
import PageWithStickyPanelLayout from "~/layouts/PageWithStickyPanelLayout.vue";
import formHandler from "~/mixins/formHandler";
import Cart from "~/modules/cart/services/Cart";
import CartItem from "~/modules/cart/services/CartItem";
import Ads from "~/modules/ad/services/Ads";
import ShareCart from "~/modules/cart/components/ShareCart.vue";
import ModalLayout from "~/layouts/ModalLayout.vue";
import AdSuggestionCart from "~/modules/cart/components/AdSuggestionCart.vue";
import CartResumeCard from "~/modules/cart/components/CartResumeCard.vue";
import { ElNotification } from 'element-plus'

function toIsoString(date) {
  let tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function(num) {
        let norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };
  return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);
}

export default {
  name: "AsksTestLayout",
  components: {PageWithStickyPanelLayout, ShareCart, ModalLayout, AdSuggestionCart, CartResumeCard},
  ApiUrl: 'user/panier-edit',
  mixins: [formHandler],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  data () {
    return {
      openShareModal: false,
      unavailableCartDays: [],
      serviceSetting: {},
      loadingDeleteCartItem: {},
      loadingAddShipping: false,
      loadingRemoveShipping: false,
      loadingValidate: false,
      serviceItemId: null,
      forceUpdateKey: 0,
      isResetting: false,
      sendFormTimeout: null,
      isUpdating: false,
      localModelEntity: null
    }
  },
  watch: {
    edit: {
      deep: true,
      handler (val) {
        if (val && val.serviceItem && Array.isArray(val.serviceItem) && val.serviceItem.length) {
          this.serviceSetting = val.serviceItem[0].setting
          this.serviceItemId = val.serviceItem[0].id
        } else if (val.proprietaire_annonce && val.proprietaire_annonce.service && Array.isArray(val.proprietaire_annonce.service) && val.proprietaire_annonce.service.length && val.proprietaire_annonce.service[0].service_settings && val.proprietaire_annonce.service[0].service_settings.length) {
          this.serviceSetting = val.proprietaire_annonce.service[0].service_settings[0]
        }
      }
    },
    'edit.serviceItem': {
      deep: true,
      handler(newVal, oldVal) {
        if (this.isResetting || this.loadingRemoveShipping || this.loadingAddShipping || this.isUpdating) {
          return;
        }

        const newValJson = JSON.stringify(newVal || []);
        const oldValJson = JSON.stringify(oldVal || []);
        
        if (newValJson !== oldValJson || this.forceUpdateKey > 0) {
          if (this.sendFormTimeout) {
            clearTimeout(this.sendFormTimeout);
          }
          
          this.isUpdating = true;
          
          this.sendFormTimeout = setTimeout(async () => {
            try {
              if (!this.edit.panier || !this.edit.panier.id) {
                return;
              }
              await this.$nextTick();
              await this.sendForm(true, {
                Cart: {
                  serviceItem: this.edit.serviceItem
                }
              }, `user/panier-edit/${this.edit.panier.id}`, 'Cart', {
                dataTransformer: (responseData) => {
                  let transformedData;
                  
                  if (responseData?.data?.data) {
                    transformedData = {
                      data: {
                        Cart: responseData.data.data.Cart,
                        serviceItems: responseData.data.data.serviceItems || []
                      }
                    };
                  } else if (responseData?.data) {
                    transformedData = {
                      data: {
                        Cart: responseData.data.Cart,
                        serviceItems: responseData.data.serviceItems || []
                      }
                    };
                  } else {
                    transformedData = { 
                      data: { 
                        Cart: responseData?.Cart || {}, 
                        serviceItems: responseData?.serviceItems || [] 
                      } 
                    };
                  }
                  
                  if (transformedData.data.Cart) {
                    this.edit = {
                      ...this.edit,
                      ...transformedData.data.Cart,
                      serviceItem: transformedData.data.serviceItems
                    };
                  }
                  
                  return transformedData;
                }
              });
            } finally {
              this.isUpdating = false;
            }
          }, 300);
        }
      }
    }
  },
  computed: {
    displayedErrorNotification () {
      return this.formErrors && this.formErrors['globalError'] ? this.formErrors['globalError'] : true
    },
    formKey () {
      return 'Cart'
    },
    isUserCart () {
      return this.ModelEntity && this.ModelEntity.user_id && this.Auth.id === this.ModelEntity.user_id
    },
    CategorizeProducts () {
      if (!this.ModelEntity || !this.ModelEntity.cartItems) {
        return []
      }
      const categorized = this.ModelEntity.cartItems.reduce((categorize, cartItem, key) => {
        let hasCategory = categorize.findIndex((category) => {
          return category && cartItem && cartItem.annonces && cartItem.annonces.souscategorieannonce && category.id === cartItem.annonces.souscategorieannonce.id
        })
        cartItem['key'] = key
        if (hasCategory === -1) {
          let id = null
          let name = null
          if (cartItem && cartItem.annonces && cartItem.annonces.souscategorieannonce) {
            id = cartItem.annonces.souscategorieannonce.id
            name = cartItem.annonces.souscategorieannonce.nom
          }
          categorize.push({
            id: id,
            name: name,
            items: [cartItem]
          })
        } else {
          categorize[hasCategory].items.push(cartItem)
        }
        return categorize
      }, [])
      return categorized
    },
    Model () {
      return Cart
    },
    hadShippingService ()
    {
      return this.edit.serviceItem && this.edit.serviceItem.length
    },
    cartTitle () {
      return this.isUserCart ? this.$t('valid-cart.checkout-page.title') : this.$t('valid-cart.checkout-page.shared-cart')
    },
    shipping: {
      get () {
        return this.edit.serviceItem && this.edit.serviceItem.length ? this.edit.serviceItem[0].setting.name : this.serviceSetting && this.serviceSetting.name ? this.serviceSetting.name : 'distanceMin'
      },
      set (val) {
        if (this.ModelEntity.proprietaire_annonce.service && this.ModelEntity.proprietaire_annonce.service.length) {
          let serviceSettingIndex = this.ModelEntity.proprietaire_annonce.service[0].service_settings.findIndex((setting) => {
            return setting.name === val
          })
          if (serviceSettingIndex === -1) {
            serviceSettingIndex = 0
          }
          
          const newSetting = {...this.ModelEntity.proprietaire_annonce.service[0].service_settings[serviceSettingIndex]}
          this.serviceSetting = newSetting
          
          if (this.edit.serviceItem && this.edit.serviceItem.length) {
            this.edit.serviceItem[0].setting = newSetting
          }
        }
      }
    },
    serviceValue () {
      return this.serviceSetting && this.serviceSetting.value ? '+ ' + this.$currencyFormat.format(parseFloat(this.serviceSetting.value)) : ''
    },
    ModelEntity: {
      get() {
        return this.localModelEntity || (this.id ? this.fullQuery.find(this.id) : new this.Model())
      },
      set(value) {
        this.localModelEntity = value
      }
    },
    hasDeliveryService() {
      return this.ModelEntity?.proprietaire_annonce?.service?.some(service => {
        return service.name === 'livraison' && service.validated === true;
      });
    }
  },
  methods: {
    validate () {

    },
    async openModal () {
      this.openShareModal = true
    },
    async closeModal () {
      this.openShareModal = false
    },
    async validateCart () {
      try {
        this.loadingValidate = true
        let response = await this.sendForm(true)
        if (response && response.response && response.response.data && response.response.data.success) {
          this.updateEditContent(response)
          this.saveResponseOtherData(response)
          window.location.href = this.path('lighty_share_user_send_message_proprietaire', {id:this.id})

        }
      } catch (e) {
        console.error(e)
      }
      this.loadingValidate = false

    },
    transformHook (edit) {
      let newStartAt = edit.startAt
      let newEndAt = edit.endAt
      if (typeof newStartAt === 'string') {
        newStartAt = new Date(newStartAt)
      }
      if (typeof newEndAt === 'string') {
        newEndAt = new Date(newEndAt)
      }
      let newEdit = {
        id: edit.id,
        cartItems: edit.cartItems.map((cartItem) => {
          return {
            id: cartItem.id,
            ad_id: cartItem.annonces.id,
            quantity: cartItem.quantity,
          }
        }),
        serviceItem: edit.serviceItem ? edit.serviceItem.map(item => ({
          ...(item.id ? { id: item.id } : {}),
          ...(item.service ? { service: { id: item.service.id } } : {}),
          setting: {
            id: item.setting.id,
            value: item.setting.value
          },
          valide: item.valide === true || item.valide === 'true' || item.valide === '1'
        })) : [],
        startAt: toIsoString(newStartAt),
        endAt: toIsoString(newEndAt),
      }
      return newEdit
    },
    afterFetchSuccess (response) {
      if (response?.response?.data?.data?.Cart) {
        this.localModelEntity = { 
          ...response.response.data.data.Cart,
          serviceItem: response.response.data.data.serviceItems 
        };
        this.edit = { 
          ...response.response.data.data.Cart,
          panier: response.response.data.data.Cart 
        };
      } else if (response?.response?.data?.Cart) {
        this.localModelEntity = response.response.data.Cart;
        this.edit = { 
          ...response.response.data.Cart,
          panier: response.response.data.Cart 
        };
      }
      
      this.saveResponseOtherData(response);
    },
    onAddCartItem (response) {
      this.updateEditContent(response)
      this.saveResponseOtherData(response, false)
      if (response && response.response && response.response.data && response.response.data.form_errors && response.response.data.form_errors.globalError) {
        ElNotification({
          title: response.response.data.form_errors.globalError,
          type: 'error'
        })
      }
    },
    afterFormSend (response) {
      this.updateEditContent(response)
      this.saveResponseOtherData(response)
    },
    saveResponseOtherData (response, formErrors = true) {
      if (response && response.response && response.response.data) {
        if (response.response.data.availableCartQuantity) {
          this.response.availableCartQuantity = response.response.data.availableCartQuantity
          this.availableCartQuantity = response.response.data.availableCartQuantity
        }
        if (response.response.data.unavailableCartDays) {
          this.unavailableCartDays = response.response.data.unavailableCartDays
        }
        if (response.response.data.form_errors && formErrors) {
          this.formErrors = response.response.data.form_errors
        }
      }
    },
    async addShipping() {
      if (this.loadingAddShipping) {
        return;
      }
      
      if (!this.ModelEntity) {
        return;
      }

      if (!this.ModelEntity.proprietaire_annonce) {
        return;
      }

      if (!this.ModelEntity.proprietaire_annonce.service || !this.ModelEntity.proprietaire_annonce.service.length) {
        return;
      }

      if (!this.edit) {
        return;
      }

      if (!this.edit.panier) {
        if (this.ModelEntity.panier) {
          this.edit = {
            ...this.edit,
            panier: this.ModelEntity.panier
          };
        } else {
          return;
        }
      }
      
      this.loadingAddShipping = true;
      
      try {
        const service = this.ModelEntity.proprietaire_annonce.service[0];

        if (!service || !service.id) {
          throw new Error('Service non disponible');
        }

        if (!this.serviceSetting || !this.serviceSetting.id) {
          throw new Error('Configuration du service non disponible');
        }

        this.edit.serviceItem = [{
          service: { id: service.id },
          setting: {
            id: this.serviceSetting.id,
            value: this.serviceSetting.value
          },
          valide: true
        }];

        const formData = new FormData();
        const cartData = {
          Cart: {
            serviceItem: [{
              service: { id: service.id },
              setting: {
                id: this.serviceSetting.id,
                value: this.serviceSetting.value
              },
              valide: true,
              service_type: this.shipping || 'distanceMin'
            }]
          }
        };
        formData.append('Cart', JSON.stringify(cartData.Cart));
        
        const response = await this.Model.api().post(
          'user/panier-edit/' + this.edit.panier.id, 
          formData,
          {
            save: true,
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data'
            }
          }
        );

        if (!response?.response?.data) {
          throw new Error('Réponse invalide du serveur');
        }

        this.updateEditContent(response);
        this.saveResponseOtherData(response);
      } catch (error) {
        this.edit.serviceItem = [];
        
        ElNotification({
          title: 'Erreur',
          message: error.message || 'Impossible d\'ajouter le service de livraison',
          type: 'error'
        });
      } finally {
        this.loadingAddShipping = false;
      }
    },
    async removeShipping() {
      if (!this.loadingRemoveShipping) {
        this.loadingRemoveShipping = true;
        this.isResetting = true;

        try {
          const serviceId = this.serviceItemId;

          if (!serviceId) {
            throw new Error('ID du service non disponible');
          }

          this.edit.serviceItem = [];
          this.serviceItemId = null;
          this.forceUpdateKey = Date.now();

          const response = await CartItem.api().delete(`user/supprimer-panier-service/${serviceId}`, {
            dataTransformer: (responseData) => {
              return {
                data: {
                  CartItem: responseData
                }
              };
            }
          });

          if (response?.response?.data?.success) {
            if (response.response.data.cart) {
              this.edit = {
                ...this.edit,
                ...response.response.data.cart,
                serviceItem: []
              };
              
              if (this.localModelEntity) {
                this.localModelEntity = {
                  ...this.localModelEntity,
                  ...response.response.data.cart,
                  serviceItem: []
                };
              }
            }

            if (this.sendForm) {
              await this.sendForm(true);
            }
          } else {
            throw new Error('La suppression a échoué');
          }
          
        } catch (error) {
          if (this.serviceItemId) {
            this.edit.serviceItem = [{ id: this.serviceItemId, setting: this.serviceSetting }];
          }
          
          ElNotification({
            title: 'Erreur',
            message: 'Impossible de supprimer le service de livraison',
            type: 'error'
          });
        } finally {
          this.loadingRemoveShipping = false;
          this.isResetting = false;
        }
      }
    },
    async deleteCartItem (id) {
      if (!this.loadingDeleteCartItem[id]) {
        this.loadingDeleteCartItem[id] = true
        let url = 'user/supprimer-panier-produit/' + id
        try {
          let ad = this.edit.cartItems.find((cartItem) => {
            return cartItem.id === id
          })
          let adId = ad.annonces.id
          let removedCartItem = await CartItem.api().delete(url, {
            delete: id
          })
          await Ads.api().get('user/annonce/' + adId)
          await this.sendForm(true)
        } catch (e) {
          console.error(e)
        }
        this.loadingDeleteCartItem[id] = false
      }
    },
    preQuery (query) {
      query.with(['user.ImageProfil', 'user.infoSociete', 'cartItems.annonces.cover', 'cartItems.annonces.bundle.bundleItems'])
    },
    onAddClick (id) {

    },
    formatCoverPath (path) {
      if (path) {
        if (path.includes('/display-media') === false) {
          return '/display-media' + path + '?w=300&r=1'
        } else {
          return path + '?w=300&r=1'
        }
      } else {
        return '/img/sans-photo.jpg'
      }
    }
  }
}
</script>

<template>
  <div class="container">
    <PageWithStickyPanelLayout>
    <template #beforePanel>
      <div class="product-creation-page-title">
        <h2>{{cartTitle}}</h2>
        <div v-if="formErrors" class="alert-cart-errors" >
          <el-alert :closable="false" v-if="formErrors && formErrors.globalError" :title="formErrors.globalError" type="error" />
        </div>
        <div v-for="category in CategorizeProducts" class="category-section">
          <div class="subtitle">
            <h3>{{category.name}}</h3>
          </div>
          <div v-for="(item) in category.items" class="cart-item-grid">
            <div class="product-cover">
              <img :src="formatCoverPath(item.annonces.cover.path)" alt="">
            </div>
            <div class="cart-item-edit">
              <div class="title-with-price">
                <h4 class="product-title"><a target="_blank"  :href="path('lighty_share_user_detail_annonce',{ 'id': item.annonces.id})">{{item.titre}}</a></h4>
                <span class="price-left" v-if="edit.cartItems && edit.cartItems[item.key]">{{ $currencyFormat.format(edit.cartItems[item.key].quantity * ((item.prixNormal / edit.nbrJourUtilisation)/ModelEntity.cartItems[item.key].quantity)) }} / jour</span>
              </div>
              <div v-if="item && item.annonces && item.annonces.bundle && item.annonces.bundle.bundleItems && edit.cartItems && edit.cartItems.length && edit.cartItems[item.key]">
                <div v-for="bundleItem in item.annonces.bundle.bundleItems" :href="path('lighty_share_user_detail_annonce',{ 'id': bundleItem.annonceId})" target="_blank" class="bundle-item-card" >
                  <div class="img">
                    <img :src="formatCoverPath(bundleItem.cover)" alt="">
                  </div>
                  <a :href="path('lighty_share_user_detail_annonce',{ 'id': bundleItem.annonceId})" class="info">
                    <div class="quantity">
                      x {{ edit.cartItems[item.key].quantity * bundleItem.quantity }}
                    </div>
                    <div class="title">
                      {{bundleItem.title}}
                    </div>
                  </a>
                </div>
              </div>
              <div class="inline-form-item" v-if="edit.cartItems && edit.cartItems[item.key]">
                <label for="">Quantité :</label>
                <el-select v-model="edit.cartItems[item.key].quantity" class="m-2" placeholder="Select" style="width: 80px" :disabled="response && response.availableCartQuantity && response && response.availableCartQuantity[item.annonces.id] ? response.availableCartQuantity[item.annonces.id].stockAvailable <= 1 : false " @change="sendForm">
                  <el-option
                      style="width: 80px"
                      v-for="n in response && response.availableCartQuantity && response && response.availableCartQuantity[item.annonces.id] ? response.availableCartQuantity[item.annonces.id].stockAvailable : 0"
                      :key="n"
                      :label="n"
                      :value="n"
                  />
                </el-select>
              </div>
              <el-button :loading="loadingDeleteCartItem[item.id]" size="small" icon="delete" class="float-right" @click="deleteCartItem(item.id)">
                Supprimer
              </el-button>
            </div>
          </div>
        </div>
        <div class="category-section" v-if="hasDeliveryService">
          <div class="subtitle">
            <h3>Service à ajouter</h3>
          </div>
          <div class="cart-item-grid">
            <div class="product-cover">
              <img :src="asset('img/new/img-15.jpg')" alt="">
            </div>
            <div class="cart-item-edit">
              <div class="d-flex justify-content-between align-items-start">
                <div>
                  <h4 class="product-title mb-1">Livraison du matériel</h4>
                  <small class="text-muted">Service fournis par le loueur</small>
                </div>
                <span class="price-left">{{ serviceValue }}</span>
              </div>
              <div class="inline-form-item">
                <label for="">{{$t('valid-cart.add-option.delivery.range')}}</label>
                <el-select v-model="shipping" class="m-2" placeholder="Select" style="width: 200px" @change="sendForm">
                  <el-option
                      :label="$t('valid-cart.add-option.delivery.km.1')"
                      :value="'distanceMin'"
                  />
                  <el-option
                      :label="$t('valid-cart.add-option.delivery.km.2')"
                      :value="'distanceMax'"
                  />
                </el-select>
              </div>
              <el-button :loading="loadingAddShipping" v-if="(!hadShippingService && !loadingRemoveShipping) || (hadShippingService && loadingAddShipping)" plain type="info" size="large" class="btn btn-primary btn-sm float-left" @click="addShipping()">
                Ajouter
              </el-button>
              <el-button :loading="loadingRemoveShipping" v-if="(hadShippingService && !loadingAddShipping) || (!hadShippingService && loadingRemoveShipping)" size="small" icon="delete" class="float-right" @click="removeShipping()">
                Supprimer
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #panel>
      <div class="resume-card-tools">
        <a href="" @click.prevent="openModal" class="item"><span class="icon-kit">&#xe9d1</span> Partager</a>
        <a :href="'/user/download-pdf-devis/'+edit.id" class="item">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-filetype-pdf" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
          </svg> Devis</a>
      </div>
      <CartResumeCard v-if="ModelEntity && edit" :loading-validate="loadingValidate" :cart="ModelEntity" v-model="edit" @change="sendForm" :unavailableDays="unavailableCartDays" @validate="validateCart" >

      </CartResumeCard>
    </template>
    <template #afterPanel>
      <h3>After Panel</h3>
    </template>
    <template #phone-actions>
      <div>
        <el-button @click="validate" :loading="loadingValidate" size="large" class="btn btn-short btn-primary mt-4 save-cart">{{ $t('valid-cart.cart-data.validate-btn') }}</el-button>
      </div>
    </template>
    </PageWithStickyPanelLayout>
    <Teleport to="body">
      <ModalLayout :show="openShareModal" @close="closeModal">
        <template #header>
          <h3><span class="icon-kit">&#xe9d1</span>Partager le panier</h3>
        </template>
        <template #body>
          <ShareCart @close="closeModal">
          </ShareCart>
        </template>
      </ModalLayout>
    </Teleport>
  </div>
</template>

<style scoped>
.product-cover img {
  border-radius: 8px;
}
</style>