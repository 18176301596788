<!-- 
  AskEdit.vue - Composant principal pour l'édition et l'affichage d'une demande de location
  Ce composant gère :
  - L'affichage des détails de la location
  - Les interactions propriétaire/locataire
  - La modification des dates
  - Les annulations
  - Les avis
-->

<script>
// Imports des composants et services nécessaires
import AskResume from "~/modules/ask/components/AskResume.vue";
import AskCart from "~/modules/ask/components/AskCart.vue";
import ShareCart from "~/modules/cart/components/ShareCart.vue";
import ModalLayout from "~/layouts/ModalLayout.vue";
import { ElNotification } from 'element-plus'
import formHandler from "~/mixins/formHandler";
import Rental from "~/modules/ask/services/Rental";
import CancelEdit from "~/modules/ask/components/CancelEdit.vue";
import DateRangePicker from "~/components/DateRangePicker.vue";

// Fonction utilitaire pour formater les dates en ISO
function toIsoString(date) {
  let tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function(num) {
        let norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };
  return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);
}

export default {
  name: "AskEdit",
  emits: ['changeCartItems', 'update:ask'],
  ApiUrl: '/api/user/ask',
  mixins: [formHandler],
  components: {
    DateRangePicker,
    CancelEdit,
    ModalLayout, ShareCart,
    AskCart,
    AskResume
  },
  props: {
    availableCartQuantity: {
      type: Object,
      default: () => {
        return {}
      }
    },
    auth: {
      type: Object,
      default: () => {
        return {}
      }
    },
    ask: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      loading: false,
      action: null,
      noCertificationPopup: false,
      lastChangeItem: null,
      cancelModal: false,
      updateAskDatesFormAdmin: false,
      cancelEdit: {
        reason: '0',
        otherReason: '',
        message: ''
      },
      isCancelFormValid: false,
      edit: {
        
      },
      disable: "btn btn-primary max-width-300 mb-20 disabled",
      message: '',
      rateAsk: 5,
      mouseStar: 5,
      nbCancellations: 0
    }
  },
  watch: {
    ask: {
      immediate: true,
      deep: true,
      handler (val) {
        if (!val) {
          this.edit = {}
          return
        }
        try {
          const editCopy = JSON.parse(JSON.stringify(val))
          Object.assign(this.edit, editCopy)
        } catch (e) {
          console.error('Error updating edit object:', e)
          this.edit = val || {}
        }
      }
    },
  },
  computed: {
    adresseLocataire() {
      if (!this.ask || !this.ask.locataire) return ''
      const locataire = this.ask.locataire
      return `${locataire.adresse || ''}, ${locataire.codePostal || ''} ${locataire.ville || ''}, ${locataire.pays || ''}`
    },
    otherUserName () {
      if (!this.ask) {
        return ''
      }
      return this.userType === 'owner' ? this.ask.locataire.prenom : this.ask.proprietaire.prenom
    },
    formattedOtherUserName() {
      if (!this.otherUserName) return '';
      return this.otherUserName
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
    askRange: {
      get () {
        if (this.edit && this.edit.departUnix && this.edit.finUnix) {
          return [this.$moment(this.edit.departUnix, 'X').toDate(), this.$moment(this.edit.finUnix, 'X').toDate()]
        }
        return [null, null]
      },
      async set (val) {
        this.edit.departUnix = this.$moment(val[0]).unix()
        this.edit.finUnix = this.$moment(val[1]).unix()
        this.edit.startAt = toIsoString(val[0])
        this.edit.endAt = toIsoString(val[1])
        this.sendAskDates()
        // this.edit = val
      }
    },
    formKey () {
      return 'Ask'
    },
    Model () {
      return Rental
    },
    isOwnerAndTenantNotCertified () {
      return this.ask && this.ask.proprietaire && this.ask.locataire && this.ask.proprietaire.id === this.auth.id && !this.ask.locataire.lightyShareCertifed
    },
    notificationAlertClasses () {
      if (this.step.includes('LOADING')) {
        return 'alert-info loading-background'
      }
      if (this.step.includes('CANCELED')) {
        return 'alert-danger'
      } else if (this.step.includes('WAITING_DEPARTURE')) {
        return 'alert-success'
      } else if (this.step.includes('WAITING')) {
        return 'alert-info'
      } else {
        return 'alert-success'
      }
    },
    notificationIconClasses () {
      if (this.step.includes('CANCELED')) {
        return 'fa fa-ban'
      } else if (this.step.includes('WAITING_DEPARTURE')) {
        return 'far fa-check-circle'
      } else if (this.step.includes('WAITING')) {
        return 'far fa-clock'
      } else {
        return 'far fa-check-circle'
      }
    },
    notificationStatus () {
      return this.step !== "LOADING" ? this.$t('manage-ask.notifications-status.' + this.userType + '.' + this.step) : ""
    },
    displayReviewForm () {
      return this.step === 'FINISH'
    },
    step () {
      if (!this.ask) {
        return 'LOADING'
      }
      if (this.ask && this.ask.canceled) {
        if (this.ask.locationProblem && this.ask.locationProblem.by && this.ask.locationProblem.by.id === this.ask.tenantId) {
          return 'CANCELED_BY_TENANT'
        } else if (this.ask.locationProblem && this.ask.locationProblem.by && this.ask.locationProblem.by.id === this.ask.ownerId) {
          return 'CANCELED_BY_OWNER'
        } else {
          return 'CANCELED'
        }
      } else {
        if (!this.ask.statutProprietaire) {
          return 'LOADING'
        }
        if (this.ask.statutProprietaire === 1) {
          return 'WAITING_OWNER_VALIDATION'
        } else if (this.ask.statutProprietaire === 2 && this.ask.leftToPay > 0 && this.$moment(this.ask.departUnix,'X')) {
          return 'WAITING_TENANT_PAYMENT'
        } else if (this.ask.statutProprietaire === 2) {
          return 'WAITING_TENANT_CONFIRMATION'
        } else if (this.isBeforeDeparture) {
          return 'WAITING_DEPARTURE'
        } else if (this.isDepartureDay) {
          return 'DEPARTURE_DAY'
        } else if (this.isBeforeArrival) {
          return 'WAITING_ARRIVAL'
        } else if (this.isArrivalDay) {
          return 'ARRIVAL_DAY'
        } else if (this.isBetweenDays) {
          return 'IN_PROGRESS'
        } else {
          return 'FINISH'
        }
      }
    },
    authorizeCancellation () {
      return this.$moment().startOf('day').unix() < this.$moment(this.ask.departUnix, 'X').add(1, 'day').startOf('day').unix();
    },
    isReviewButtonDisabled () {
      return this.message.length < 20
    },
    isDayStarting () {
      return this.$moment(this.ask.departUnix,'X').unix() < this.$moment().unix() && this.$moment(this.ask.departUnix,'X').add(1, 'day').unix() > this.$moment().unix()
    },
    isBetweenDays() {
      if (!this.ask || !this.ask.departUnix || !this.ask.finUnix) {
        return false
      }
      return this.$moment(this.ask.departUnix, 'X').startOf('day').unix() <= this.$moment().startOf('day').unix() &&
          this.$moment(this.ask.finUnix, 'X').startOf('day').unix() >= this.$moment().startOf('day').unix();
    },
    departureDay () {
      return this.$moment(this.ask.departUnix, 'X').startOf('day').unix() + ' > ' + this.$moment().startOf('day').unix()
    },
    departureDayDebug () {
      return this.$moment(this.$moment(this.ask.departUnix, 'X').startOf('day').unix(), 'X').format('DD/MM/yyyy') + ' > ' + this.$moment(this.$moment().startOf('day').unix(), 'X').format('DD/MM/yyyy')
    },
    isBeforeDeparture() {
      return this.ask && this.ask.departUnix ? this.$moment(this.ask.departUnix, 'X').startOf('day').unix() > this.$moment().unix() : false
    },
    isBeforeArrival () {
      return this.$moment(this.ask.finUnix, 'X').startOf('day').unix() > this.$moment().startOf('day').unix();
    },
    isDepartureDay() {
      return this.$moment(this.ask.departUnix, 'X').startOf('day').unix() === this.$moment().startOf('day').unix();
    },
    isArrivalDay() {
      return this.$moment(this.ask.finUnix, 'X').startOf('day').unix() === this.$moment().startOf('day').unix();
    },
    templateClasses () {
      if (!this.ask) {
        return ''
      }
      if (this.userType === 'owner') {
        if (this.ask.statutProprietaire === 1) {
          return 'askTemplate owner'
        } else {
          return 'askTemplate tenant'
        }
        return 'askTemplate owner'
      } else {
        return 'askTemplate tenant'
      }
    },
    sinistreHref () {
      return this.ask && this.ask.panier && this.ask.proprietaire && this.ask.locataire ? "#tally-open=mZQpan&proprietaireNom=" + this.ask.proprietaire.nom + "&proprietairePrenom=" + this.ask.proprietaire.prenom + "&locatairePrenom=" + this.ask.locataire.prenom + "&locataireNom=" + this.ask.locataire.nom + "&locationValeur=" + this.ask.panier.valeurTotalMateriel + "&locationCaution=" + this.ask.panier.caution + "&locationId=" + this.ask.id + "&proprietaireMail=" + encodeURIComponent(this.ask.proprietaire.email) + "&locataireMail=" + encodeURIComponent(this.ask.locataire.email) + "&userLocationNum=" + this.ask.numeroLocation : "#"
    },
    procurationHref () {
      return this.ask && this.ask.proprietaire && this.ask.locataire ? "#tally-open=wogzMw&userId=" + this.ask.locataire.id + "&userNom=" + this.ask.locataire.nom + "&userPrenom=" + this.ask.locataire.prenom + "&userAdressePostale=" + this.adresseLocataire + "&userDateNaissance=" + this.$moment(this.ask.locataire.dateNaissance).format('DD-MM-YYYY') + "&userMail=" + encodeURIComponent(this.ask.locataire.email) + "&proprietaireMail=" + encodeURIComponent(this.ask.proprietaire.email) + "&userLocationId=" + this.ask.id + "&userLocationNum=" + this.ask.numeroLocation : ""
    },
    actionButtons () {
      if (!this.ask) {
        return []
      }
      if (this.userType === 'owner') {
        if (this.ask && this.ask.canceled && this.ask.locationProblem && this.ask.locationProblem.by && this.ask.locationProblem.by.id === this.ask.ownerId) {
          return [
            {
              label: "Revenir sur l'annulation",
              type: '',
              classes: 'btn btn-save',
              plain: true,
              loading: this.formLoading,
              onClick: () => {
                this.rollbackCancel()
              }
            }
          ]
        }
        if (this.ask && this.ask.statutProprietaire === 1) {
          return [
            {
              label: 'Accepter',
              type: '',
              classes: 'btn btn-save',
              plain: true,
              loading: this.formLoading && this.action === 'OwnerAccept',
              onClick: () => {
                this.sendOwnerAccept()
              }
            },
            {
              label: 'Refuser',
              type: '',
              classes: 'btn',
              plain: false,
              onClick: () => {
                this.cancelModal = true
              }
            }
          ]
        } else {
          return [
            {
              label: 'Messagerie',
              type: 'primary',
              classes: 'btn btn-blue',
              plain: true
            }
          ]
        }
      } else {
        if (this.ask && this.ask.statutProprietaire > 1 && this.ask.statutProprietaire < 3) {
          return [
            {
              label: 'Confirmer',
              type: '',
              classes: 'btn btn-save',
              plain: true,
              onClick: () => {
                this.tenantConfirmation()
              }
            }
          ]
        }
        return [
          {
            label: 'Messagerie',
            type: 'primary',
            classes: 'btn btn-blue',
            plain: true
          }
        ]
      }
      return []
    },
    actionButtonsFormatted () {
      return this.actionButtons.map((actionButton) => {
        if (typeof actionButton.onClick !== 'function') {
          actionButton.onClick = () => {
          }
        }
        return actionButton
      })
    },
    cancelInfoText () {
      if (this.ask && this.ask.typeAnnulation) {
        return this.$t('infos.cancel.description.' + this.ask.typeAnnulation.id)
      } else {
        return ''
      }
    },
    projectTitle () {
      return this.userType === 'tenant' ? 'Votre projet' : 'Projet du locataire'
    },
    userType () {
      if (this.ask && this.ask.tenantId === this.ask.ownerId) {
        return Array.isArray(this.$route) && this.$route.include('locataire') ? 'tenant' : 'owner'
      }
      return this.ask && this.auth && this.auth.id === this.ask.tenantId ? 'tenant' : 'owner'
    },
    startDateInfo () {
      if (!this.ask) {
        return false
      }
      const startMoment = this.$moment(this.ask.departUnix, 'X')
      return {
        day: startMoment.format('dddd'),
        date: startMoment.format('DD'),
        month: startMoment.format('MMM').toUpperCase()
      }
    },
    endDateInfo () {
      if (!this.ask) {
        return false
      }
      const endMoment = this.$moment(this.ask.finUnix, 'X')
      return {
        day: endMoment.format('dddd'),
        date: endMoment.format('DD'),
        month: endMoment.format('MMM').toUpperCase()
      }
    },
    needOwnerRefundChoices() {
      return this.ask && 
             this.ask.remboursement && 
             this.ask.remboursement.choixProprietaire === 'WAITING' && 
             this.userType === 'owner'
    },
    isUpdatable () {
      return this.ask && this.ask.statutLocataire === 1 && this.ask.statutProprietaire === 1 && this.userType === 'owner'
    },
    cancelOwnerModalNoCartItemValid () {
      return (this.userType === 'owner' && this.edit && this.edit.panier && Array.isArray(this.edit.panier.paniersAnnonce) && this.edit.panier.paniersAnnonce.filter((cartItem) => {
        return cartItem && cartItem.valide
      }).length === 0) && this.ad && !this.ad.locationProblem
    },
    secondaryActionsButtons () {
      const secondaryButtons = []
      if (this.ask && !this.ask.locationProblem) {
        if (this.authorizeCancellation && !(this.userType === 'owner' && this.ask.statutProprietaire === 1)) {
          secondaryButtons.push({
            label: this.$t('infos.actions.cancel'),
            onClick: () => {
              this.cancelModal = true
            },
            classes: "btn btn-outline-primary"
          })
        }
        if (this.ask && this.ask.statutProprietaire === 3 ) {
          secondaryButtons.push({
            label: this.$t('infos.actions.contract'),
            href: this.path('download_pdf_contrat_location', {'id': this.ask.id}),
            classes: "btn btn-outline-primary"
          })
        }
        const startMoment = this.$moment(this.ask.departUnix, 'X')
        const endMoment = this.$moment(this.ask.finUnix, 'X').add(3, 'days')
        if (this.$moment().isBetween(startMoment, endMoment) && this.userType === 'owner') {
          secondaryButtons.push({
            label: this.$t('infos.actions.sinistre'),
            href: this.sinistreHref,
            classes: "btn btn-outline-primary"
          })
        }
        if (this.ask && this.authorizeCancellation && this.userType === 'tenant') {
          secondaryButtons.push({
            label: this.$t('infos.actions.procuration'),
            href: this.procurationHref,
            classes: "btn btn-outline-primary"
          })
        }
      }
      secondaryButtons.map((btn) => {
        if (!btn.onClick) {
          btn.onClick = (e) => {
            if (btn.href) {
            } else {
              e.preventDefault()
            }
          }
        }
        return btn
      })
      return secondaryButtons
    },
    isDisabled: {
      get() {
        return this.disable
      },
      set(value) {
        this.disable = value
      }
    },
    valueStar: {
      get() {
        return this.rating
      },
      set(value) {
        this.rating = value
        var stars = this.$refs.ratings.childNodes

        for (var i = 0; i < stars.length; i++) {
          stars[i].classList.remove('selected');
        }

        for (var i = 0; i < this.mouseStar; i++) {
          stars[i].classList.add('selected');
        }
      }
    },
    deadlineDate() {
      if (!this.ask) return ''
      
      // Utiliser la date de création du location_problem comme source principale
      if (this.ask.locationProblem?.createdAt) {
        const deadline = this.$moment(this.ask.locationProblem.createdAt).add(24, 'hours')
        return deadline.format('DD/MM/YYYY à HH:mm')
      }
      
      // Fallback sur canceledAtUnix si disponible
      if (this.ask.canceledAtUnix) {
        const deadline = this.$moment.unix(this.ask.canceledAtUnix).add(24, 'hours')
        return deadline.format('DD/MM/YYYY à HH:mm')
      }
      
      return ''
    }
  },
  methods: {
    async afterFormSend () {
      if (this.action === 'OwnerCancel') {
        this.cancelModal = false
        this.cancelEdit = {
          reason: '0',
          otherReason: '',
          message: ''
        }
        ElNotification({
          title: "Votre demande a bien été annulée",
          type: 'success'
        })
      }
      if (this.action === 'RollbackOwnerCancel') {
        ElNotification({
          title: "Votre demande a bien été réactivée",
          type: 'success'
        })
      }
    },
    async tenantConfirmation () {
      if (!this.auth.lightyShareCertifed) {
        this.noCertificationPopup = true
      } else {
        window.location.href = this.path('rental_payment',{'id': this.ask.id})
      }
    },
    async sendOwnerRefundPenalties () {
      this.action = 'OwnerRefundPenalties'
      await this.sendForm(true, {
        accept: true
      }, '/api/user/ask', 'refundPenalties')
      this.action = null
    },
    async sendOwnerApplyPenalties () {
      this.action = 'OwnerApplyPenalties'
      try {
        await this.sendForm(true, {
          accept: true
        }, '/api/user/ask', 'applyPenalties')
      } catch (e) {
        // Suppression du console.log
      }
      this.action = null
    },
    async sendOwnerAccept () {
      this.action = 'OwnerAccept'
      await this.sendForm(true, {
        accept: true
      }, '/api/user/ask', 'ownerAccept')
      this.action = null
    },
    async sendAskDates () {
      this.action = 'UpdateDates'
      if (this.updateAskDatesFormAdmin) {
        this.action = 'UpdateDatesAdmin'
      }
      try {
        await this.sendForm(true, {
          startAt: this.edit.startAt,
          endAt: this.edit.endAt,
        }, '/api/user/ask', this.action)
      } catch (e) {
        console.error('Error during send ask dates:', e)
      } finally {
        this.action = null
      }
    },
    async sendReview () {
      if (!this.isReviewButtonDisabled) {
        this.action = 'sendReview'
        const formKey = this.userType === 'owner' ? 'ownerReview' : 'tenantReview'
        try {
          await this.sendForm(true, {
            rating: this.rateAsk,
            message: this.message
          }, '/api/user/ask', formKey)
        } catch (e) {
          console.error('Error during send review:', e)
        } finally {
          this.action = null
        }
      }
    },
    async sendCancelation () {
      const reasonModeles = {
        "0": 'Matériel non disponible',
        "1": "Loueur indisponible",
      }
      let reason = null
      if (this.cancelEdit.reason === '2') {
        if (!this.cancelEdit.otherReason || this.cancelEdit.otherReason.length === 0) {
          ElNotification({
            title: 'Erreur',
            message: "Veuillez renseigner une raison",
            type: 'error'
          })
          return
        } else {
          reason = this.cancelEdit.otherReason
        }
      } else {
        reason = reasonModeles[this.cancelEdit.reason]
      }
      this.action = this.userType === 'owner' ? 'OwnerCancel' : 'TenantCancel'
      const postUrl = this.userType === 'owner' ? '/api/user/ask' : '/api/user/cancel-ask'
      await this.sendForm(true, {
        ...this.cancelEdit,
        type: 'Refuse',
        reason
      }, postUrl, 'locationProblem')
      this.action = null
    },
    async rollbackCancel () {
      this.action = 'RollbackOwnerCancel'
      await this.sendForm(true, {
        rollback: true
      }, '/api/user/ask', 'rollbackCancel')
      this.action = null
    },
    async rollbackAdmin () {
      this.action = 'RollbackAdmin'
      await this.sendForm(true, {
        rollback: true
      }, '/api/user/ask', 'rollbackAdmin')
      this.action = null
    },
    async updateAskDates () {
      this.action = 'UpdateDates'
      this.updateAskDatesFormAdmin = true
    },
    async reload () {
      this.action = 'Reload'
      await this.sendForm(true, {
        reload: true
      }, '/api/user/ask', 'reload')
      this.action = null
    },

    onCancelChange (edit) {
      this.cancelEdit = {
        reason: edit.reason,
        otherReason: edit.otherReason,
        message: edit.message
      }
      // Stocker l'état de validité du formulaire
      this.isCancelFormValid = edit.isValid
    },
    closeCancelModal () {
      this.cancelModal = false
      if (this.cancelOwnerModalNoCartItemValid) {
        let cartItem = this.lastChangeItem
        if (!this.lastChangeItem) {
          cartItem = this.ask.panier.paniersAnnonce[0]
        }
        if (cartItem) {
          cartItem = {}
        }
        cartItem.valide = true
        this.$refs.askCart.updateItem(cartItem)
      }
    },
    closeNoCertifiedPopup () {
      this.noCertificationPopup = false
    },
    transformHook (edit) {
      let newStartAt = this.$moment(this.ask.departUnix, 'X')
      let newEndAt = this.$moment(this.ask.finUnix, 'X')

      let paniersAnnonce = []
      if (edit && edit.panier && edit.panier.paniersAnnonce) {
        paniersAnnonce = edit.panier.paniersAnnonce.map((panierAnnonce) => {
          return {
            id: panierAnnonce.id,
            annonce_id: panierAnnonce.annonces.id,
            valide: panierAnnonce.valide,
            quantity: panierAnnonce.quantity
          }
        })
      }
      const data = {
        id: edit.id,
        panier: {
          startAt: newStartAt.toISOString(),
          endAt: newEndAt.toISOString(),
          id: edit.panier ? edit.panier.id : null,
          cartItems: paniersAnnonce
        }
      }
      return data
    },
    async askCartComputation() {
      await this.sendForm(true, {
        Cart: {
          cartItems: this.edit.panier.paniersAnnonce.map(item => ({
            id: item.id,
            quantity: item.quantity,
            valide: item.valide
          })),
          save: true
        }
      })
    },
    async askCartChange (ask, item) {
      // Forcer la réactivité en créant un nouvel objet
      const updatedPanier = {
        ...this.edit.panier,
        prix_location_ht: ask?.panier?.prix_location_ht,
        prix_location_ttc: ask?.panier?.prix_location_ttc,
        prix_total_ht: ask?.panier?.prix_total_ht,
        prix_total_ttc: ask?.panier?.prix_total_ttc,
        paniersAnnonce: ask?.panier?.paniersAnnonce?.map(item => ({...item}))
      };

      // Mettre à jour l'objet edit avec le nouveau panier
      this.edit = {
        ...this.edit,
        panier: updatedPanier,
        prix_location_ht: ask?.panier?.prix_location_ht,
        prix_location_ttc: ask?.panier?.prix_location_ttc,
        prix_total_ht: ask?.panier?.prix_total_ht,
        prix_total_ttc: ask?.panier?.prix_total_ttc
      };

      this.lastChangeItem = item;
      this.$emit('changeCartItems', this.edit, item);

      // Force la mise à jour du composant
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    async loadAsk() {
      if (this.loading) return;
      
      try {
        this.loading = true;
        
        // Vérifier l'authentification
        if (!this.auth || !this.auth.id) {
          return;
        }
        
        // Vérifier l'ID de la location
        const askId = this.$route?.params?.id;
        if (!askId) {
          return;
        }

        // Charger la location
        const ask = await Rental.query()
          .with(['locataire', 'proprietaire', 'taxComputation'])
          .find(askId);
        
        if (!ask) {
          return;
        }
        
        // Charger les annulations si nécessaire
        if (this.userType === 'tenant' && ask.locataire) {
          try {
            const cancellationsResponse = await Rental.getNbLocationsAnnuleesDerniereAnnee(ask.locataire.id);
            ask.nbCancellations = cancellationsResponse.data;
          } catch (e) {
            console.error('Erreur lors de la récupération des annulations:', e);
          }
        }

        // Mettre à jour l'état via le watcher
        this.$emit('update:ask', ask);
        
      } catch (error) {
        console.error('Erreur lors du chargement de la location:', error);
        ElNotification({
          title: 'Erreur',
          message: 'Une erreur est survenue lors du chargement de la demande',
          type: 'error',
        });
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.loadAsk()
  }
}
</script>

<template>
  <!-- Loader pendant le chargement -->
  <div v-if="loading" class="loading-container">
    <el-skeleton :rows="10" animated />
  </div>

  <!-- Contenu principal -->
  <div v-else class="ask-container">
    <!-- Statut de la location -->
    <div class="rental-status">
      <div class="alert mb-4" :class="notificationAlertClasses" role="alert">
        <div class="alert-icon">
          <span v-if="step !== 'LOADING'" :class="notificationIconClasses"></span>
          {{notificationStatus}}
        </div>
        <span></span>
      </div>
    </div>

    <!-- Template principal de la demande -->
    <div class="askTemplate" :class="templateClasses">
      <div>
        <!-- Badge de certification si nécessaire -->
        <div v-if="ask && isOwnerAndTenantNotCertified" style="width: 100%">
          <div class="certification-badge">
            <span class="certification-icon"></span>
            <span class="certification-description">
              {{ $t('certification-badge.description-vue', {firstname: ask.locataire?.prenom }) }}
            </span>
            <a class="read-more" target="_blank" href="https://support.lightyshare.com/article/195-un-membre-non-certifie-ma-envoye-une-demande-de-location">
              {{ $t('certification-badge.more-content') }}
              <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="arrow-right">
                  <path id="Icon" d="M2.7085 7.00065H10.2918M10.2918 7.00065L6.50016 3.20898M10.2918 7.00065L6.50016 10.7923" stroke="#080025" stroke-width="1.3333" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </svg>
            </a>
          </div>
        </div>

        <!-- Section du projet -->
        <div class="section section-block">
          <h3 class="multisteps-form__title">{{ projectTitle }}</h3>
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="d-flex align-items-center">
              <span class="font-weight-bold">Titre : </span>
              <span class="ms-2">{{ ask && ask.titre_location ? ask.titre_location : '' }}</span>
            </div>
            <div>
              <span class="font-weight-bold">N° Location : </span>
              <span class="ms-2">{{ ask && ask.numeroLocation ? ask.numeroLocation : '' }}</span>
            </div>
          </div>
          <div class="description-box">
            <p v-if="ask && ask.description" v-html="ask.description"></p>
          </div>
        </div>

        <!-- Dates de location -->
        <div class="section-block">
          <h3 class="multisteps-form__title">Dates de location</h3>
          <div class="votre-demande">
            <div class="date-box depart-date">
              <div class="date">
                <h5 style="text-transform: capitalize;" v-if="startDateInfo">{{ startDateInfo.day }} <br> <span>{{ startDateInfo.date }}</span><br><span>{{ startDateInfo.month }}</span></h5>
              </div>
              <h4>
                {{$t('infos.date.start-label')}} <br> <span>{{$t('infos.date.start')}}</span>
              </h4>
            </div>
            <div class="arrow">
              <i class="fas fa-chevron-right"></i>
            </div>
            <div class="date-box retour-date">
              <div class="date">
                <h5 style="text-transform: capitalize;" v-if="endDateInfo">{{ endDateInfo.day }} <br> <span>{{ endDateInfo.date }}</span><br><span>{{ endDateInfo.month }}</span></h5>
              </div>
              <h4>
                {{$t('infos.date.return-label')}}<br> <span>{{$t('infos.date.return')}}</span>
              </h4>
            </div>
          </div>
        </div>

        <!-- Section des avis -->
        <div v-if="ask && ask.avisDone && ask.avisDone.notation" class="contrat-de-location notation-done-section">
          <h3 class="multisteps-form__title">Votre avis</h3>
          <p>{{ ask.avisDone.content }}</p>
          <el-rate v-model="ask.avisDone.notation.note" size="large" score-template="{value}/5" :show-score="true" :disabled="true"/>
        </div>

        <!-- Formulaire d'avis si nécessaire -->
        <div v-else-if="displayReviewForm" class="contrat-de-location notation-section">
          <h3 class="multisteps-form__title">{{$t('infos.avis.label')}} </h3>
          <el-rate v-model="rateAsk" size="large" score-template="{value}/5" :show-score="true" class="mb-4"/>
          <div class="product-creation-form-item">
            <div class="product-form-field mrg-25 p-0">
              <label class="creation-description">
                {{$t('infos.avis.subtitle.2')}}
                <div class="lableNote" :class="{ 'text-danger': message.length > 0 && message.length < 10 }">
                  <span>{{ message.length }}</span>
                  <span>/ 1000</span>
                  <small class="min-chars" :class="{ 'text-danger': message.length > 0 && message.length < 10 }">
                    (minimum 10 caractères)
                  </small>
                </div>
              </label>
              <textarea 
                name="avis" 
                class='form-control-input' 
                maxlength="1000"  
                :placeholder="$t('infos.avis.placeholder', {user: formattedOtherUserName})" 
                v-model="message"
              ></textarea>
              <div class="invalid-feedback">

              </div>
              <el-button 
                :loading="action === 'sendReview'" 
                size="large" 
                :disabled="message.length < 10" 
                type="primary" 
                class="btn btn-primary" 
                name="accept" 
                @click="sendReview"
              >
                Publier
              </el-button>

            </div>
          </div>
        </div>

        <!-- Section admin si l'utilisateur est admin-->

        <!-- <div v-if="auth && Array.isArray(auth.rolesList) && auth.rolesList.includes('ROLE_ADMIN')">
          <hr>
          <h3 class="multisteps-form__title">Actions administrateur</h3>
          <p>Pour le debug</p>
          <div class="row col-sm-12">
            <el-button :loading="action === 'RollbackAdmin'" size="large" class="btn btn-outline" name="accept" @click="rollbackAdmin">
              Etape précédente
            </el-button><el-button :loading="action === 'Reload'" size="large" class="btn btn-outline" name="accept" @click="reload">
              Recharger
            </el-button>
            <el-button size="large" class="btn btn-outline" name="accept" @click="updateAskDates">
              Modifier le dates
            </el-button>
          </div>
          <hr>
        </div> -->

        <!-- Section des pénalités si nécessaire -->
        <div v-if="needOwnerRefundChoices" class="p-4 rounded-lg mb-5" style="background-color: #FFF1F1; border: 1px solid #FECACA;">
          <div class="mb-3">
            <table>
              <tr>
                <td class="align-middle pr-2">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 8V12M12 16H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#EF4444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </td>
                <td class="align-middle">
                  <strong style="color: #EF4444;" class="text-lg">{{$t('infos.penalty.label')}}</strong>
                </td>
              </tr>
            </table>
          </div>
          <p class="text-gray-900 text-base mb-2">
            {{$t('infos.penalty.refund')}} <strong>{{ ask.remboursement.montantRemboursementProprietaire }} € TTC</strong>.
            Sans annulation des pénalités avant le <strong>{{ deadlineDate }}</strong>, les pénalités seront automatiquement appliquées.</p>
          <p style="font-size: 12px; color: #4B5563;">
            Le locataire sera averti du remboursement par e-mail.
          </p>

          <!-- Buttons -->
          <div class="row col-sm-12 mt-4">
            <el-button :loading="action === 'OwnerRefundPenalties'" size="large" style="background-color: #EF4444; border: none; color: white;" name="cancel" @click="sendOwnerRefundPenalties">
              {{$t('infos.penalty.btnRefund')}}
            </el-button>
          </div>
        </div>

        <!-- Actions de location -->
        <div v-if="secondaryActionsButtons.length" class="contrat-de-location secondary-actions-buttons-list section-block">
          <h3 class="multisteps-form__title">{{ $t('infos.actions.label') }}</h3>
          <p>
            {{ $t('infos.actions.subtitle') }}
          </p>
          <div class="button">
            <a v-for="actionLink in secondaryActionsButtons" :href="actionLink.href" :class="actionLink.classes" @click="actionLink.onClick">{{actionLink.label}}</a>
          </div>
        </div>

        <!-- Panier de la demande -->
        <div class="section-block">
          <AskCart ref="askCart" v-model="edit" :ask="ask" :modifiable="isUpdatable" @change="askCartChange" :available-cart-quantity="availableCartQuantity">
          </AskCart>
        </div>

        <!-- Section des conditions d'annulation -->
        <div class="a-savior-section section-block">
          <h3 class="multisteps-form__title">
            {{$t('infos.cancel.label')}}
          </h3>
          <p><strong>
            {{$t('infos.cancel.subtitle')}}
          </strong><br>
            <p class="mb-0" v-html="cancelInfoText">
            </p>
          </p>
        </div>
      </div>

      <!-- Résumé de la demande (version desktop) -->
      <div>
        <AskResume class="only-desktop mb-4" :user-type="userType" :ask="edit">
          <template #between>
            <DateRangePicker v-if="updateAskDatesFormAdmin" v-model="askRange" @change="askCartChange"></DateRangePicker>
          </template>
          <template #after>
            <el-button v-for="actionButton in actionButtonsFormatted" plain :loading="!!actionButton.loading" size="large" class="fullwith" :class="actionButton.classes" :type="actionButton.type" @click.prevent="actionButton.onClick">{{ actionButton.label }}</el-button>
          </template>
        </AskResume>
      </div>

      <!-- Actions principales (version mobile) -->
      <div class="ask-primary-action only-phone">
        <el-button v-for="actionButton in actionButtonsFormatted" plain size="large" class="fullwith" :class="actionButton.classes" :type="actionButton.type" @click.prevent="actionButton.onClick" :loading="!!actionButton.loading">{{ actionButton.label }}</el-button>
      </div>

      <!-- Modales -->
      <Teleport to="body">
        <!-- Modal d'annulation -->
        <ModalLayout :show="cancelModal || cancelOwnerModalNoCartItemValid" @close="closeCancelModal" :header="false" :footer="true" modal-class="full-width-modal">
          <template #body>
            <el-scrollbar ref="scrollbarRef" max-height="80vh" class="remove-modal-padding">
              <h5 class="modal-title mb-2">{{ $t('modals.cancel.' + userType + '.title') }}</h5>
              <CancelEdit :userType="userType" :ask="ask" @change="onCancelChange">
              </CancelEdit>
            </el-scrollbar>
          </template>
          <template #footer>
            <el-button class="btn" plain size="large" @click="closeCancelModal">{{$t('modals.cancel.' + userType + '.close')}}</el-button>
            <el-button plain class="btn btn-save" size="large" @click="sendCancelation" :loading="formLoading" :disabled="!isCancelFormValid">{{$t('modals.cancel.' + userType + '.btnCancel')}}</el-button>
          </template>
        </ModalLayout>
      </Teleport>

      <!-- Modal de certification -->
      <el-dialog v-model="noCertificationPopup" class="no-certification-popup" :close-on-click-modal="true">
        <div class="no-certification-popup-inner">
          <div class="welcome-model-header">
            <h3><img :src="asset('img/svg/thumb-icon.svg')">{{$t('certified.title')}}</h3>
          </div>
          <div class="welcome-model-content">
            <p>
              {{$t('certified.description')}}
            </p>
            <div class="welcome-model-btns">
              <a :href="path('lighty_share_lighty_share_account_settings')  + '#section-certification'" class="btn btn-primary">
                {{$t('certified.button.accept')}}
              </a>
              <a href="#" @click="closeNoCertifiedPopup" class="btn btn-close-model">
                {{$t('certified.button.later')}}
              </a>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<style scoped>
.loading-container {
  padding: 20px;
}
.ask-container {
  width: 100%;
}
.section-block {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
}

/* Pour le dernier bloc, pas de marge en bas */
.section-block:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.text-danger {
  color: red !important;
}
.min-chars {
  margin-left: 8px;
  font-size: 0.8em;
}

.votre-demande {
  margin: 0;
  padding: 0;
}

.description-box {
  background: #f8f8f8;
  border-radius: 8px;
  padding: 15px;
  margin-top: 10px;
  line-height: 1.5;
}

.description-box p {
  margin: 0;
  color: #333;
}
</style>